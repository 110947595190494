import { scrollSpy, Offcanvas } from "bootstrap/js";

document.addEventListener("DOMContentLoaded", function() {

  const scrollSpy_JS = new scrollSpy(document.body, {
    target: '#MainNavigation'
  });


  var nav = document.getElementById("MainNav");
  var navHeight = nav.offsetHeight;

  // Cache the last known scroll position and the state
  var lastScrollY = window.scrollY;
  var isScrolled = false;

  function onScroll() {
    var currentScrollY = window.scrollY;

    // Check if the scroll position has passed the nav height
    if (currentScrollY > navHeight) {
      if (!isScrolled) {
        nav.classList.add('scrolled');
        isScrolled = true;
      }
    } else {
      if (isScrolled) {
        nav.classList.remove('scrolled');
        isScrolled = false;
      }
    }

    lastScrollY = currentScrollY;
  }

  // Use a passive event listener for better scroll performance
  document.addEventListener("scroll", onScroll, { passive: true });
  
  // Check the scroll position immediately when the page loads
  onScroll();
});


const locations = [
  {
      "image": "/images/donnington-valley-hotel.jpg",
      "name": "Donnington Valley Hotel",
      "link": "https://www.donningtonvalley.co.uk/",
      "tel": "01635551199",
      "address": "Old Oxford Rd, Donnington, Newbury RG14 3AG",
      "tag": "Nearest"
  },
  {
      "image": "/images/cheq.jpeg",
      "name": "The Chequers Hotel",
      "link": "https://chequershotelnewbury.co.uk/",
      "tel": "+44 1635 38000",
      "address": "The Chequers Hotel, 6-8 Oxford St, Newbury RG14 1JB"
  },
  {
      "image": "/images/prem.jpg",
      "name": "Premier Inn",
      "link": "https://www.premierinn.com/gb/en/hotels/england/berkshire/newbury/newbury-town-centre-london-road.html",
      "tel": "03332346614",
      "address": "Park Way, Newbury RG14 1EE"
  },
  {
      "image": "/images/GB0844_Newbury_London_Rd_EXT_2208.jpg",
      "name": "Travelodge",
      "link": "https://www.travelodge.co.uk/hotels/508/London-Newbury-Road-hotel",
      "tel": "08719846475",
      "address": "Park Way, Newbury RG14 1EE"
  }
];

function GenerateAcommCards(locations) {
  const nearbyAccomContainer = document.getElementById('nearbyAccomContainer');
  nearbyAccomContainer.innerHTML = "";

  // Create a DocumentFragment to batch DOM updates
  const fragment = document.createDocumentFragment();

  locations.forEach(item => {
      const template = `
      <div class="col-12 col-sm-6">
          <div class="card h-100">
              <div class="row g-0 h-100">
                  <div class="col-lg-5">
                      <img src="${item.image}" class="img-fluid rounded-start h-100" alt="${item.name}">
                  </div>
                  <div class="col-lg-7">
                      ${item.tag ? `<div class="card-header">${item.tag}</div>` : ''}
                      <div class="card-body">
                          <h5 class="card-title">${item.name}</h5>
                          <ul class="list-group list-group-flush">
                              <li class="list-group-item"><a href="tel:${item.tel}">${item.tel}</a></li>
                              <li class="list-group-item">${item.address}</li>
                              <li class="list-group-item"><a class="btn btn-primary" href="${item.link}">Website</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      `;

      const templateElement = document.createElement('div');
      templateElement.innerHTML = template;

      // Append the first child of templateElement (the actual card) to the fragment
      fragment.appendChild(templateElement.firstElementChild);
  });

  // Append the entire fragment to the container
  nearbyAccomContainer.appendChild(fragment);
}

setTimeout(() => {
  GenerateAcommCards(locations);
}, 2000);